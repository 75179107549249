import * as mqtt from 'async-mqtt'
import { AsyncMqttClient } from 'async-mqtt'
import {
  MqttValue,
  MqttContainer,
  MqttSubscription,
  MqttMessage,
  mqttValueConversion,
  instanceOfMqttValue,
} from '../mqttDefinitions'
import { defineStore } from 'pinia'
import discoverService from '@/service/discoverService'

export const useMqttStore = defineStore({
  id: 'mqttStore',
  state: () => ({
    connection: {
      protocol: 'ws',
      host: 'bikefitting',
      // ws: 8083; wss: 8084
      port: 9001,
      endpoint: '/mqtt',
      // for more options, please refer to https://github.com/mqttjs/MQTT.js#mqttclientstreambuilder-options
      clean: true,
      connectTimeout: 4000, // ms
      reconnectPeriod: 1000, // ms
      rejectUnauthorized: false,
    },

    client: {} as AsyncMqttClient,

    connected: false,
    connecting: false,
    connectionError: '',

    retryTimes: 0,

    messages: [] as MqttMessage[],
  }),

  getters: {
    getMessageOnTopic: (state) => (definition: MqttValue) => {
      const returnValue = state.messages.find(
        (message) => message.topic == definition.topic,
      )?.payload
      return mqttValueConversion(
        returnValue,
        definition.conversionValue,
        'receive',
      )
    },

    getRawValueOnTopic: (state) => (topic: string) => {
      const returnValue = state.messages.find(
        (message) => message.topic == topic,
      )?.payload
      return returnValue
    },

    getMessagesOnTopics: (state) => (topics: string[]) => {
      const returnValue = state.messages.filter((message) =>
        topics.includes(message.topic),
      )
      return returnValue
    },
  },

  actions: {
    async createConnection() {
      try {
        if (this.connecting) {
          console.log('closing running connection attempts')
          await this.client.end()
          this.initData()
        }
        const { protocol, host, port, endpoint, ...options } = this.connection
        const connectUrl = `${protocol}://${host}:${port}${endpoint}`
        this.connecting = true
        this.client = await mqtt.connect(connectUrl, options)

        if (this.client.on) {
          this.client.on('connect', () => {
            console.log('client connected')
            /*
            this.doRawPublish(
              'bikefitting/Controller/refresh/set',
              JSON.stringify({}),
              this.client,
            )
              */
            this.connected = true
            this.connecting = false
            this.connectionError = ''
          })
          this.client.on('reconnect', this.handleOnReConnect)
          this.client.on('error', (error) => {
            console.log('connection error:', error)
          })
          this.client.on('disconnect', () => {
            console.log('client disconnected')
            this.initData()
          })
          this.client.on('end', () => {
            console.log('client ended')
            this.initData()
          })
          this.client.on('offline', () => {
            console.log('client offline')
            this.connected = false
          })
          this.client.on('message', (topic: string, message) => {
            const messageObj = JSON.parse(message.toString())
            if (messageObj !== undefined) {
              if (Number.parseInt(messageObj.value)) {
                this.updateArray(this.messages, {
                  topic: topic,
                  payload: Number.parseInt(messageObj.value),
                })
              } else {
                this.updateArray(this.messages, {
                  topic: topic,
                  payload: messageObj,
                })
              }
            }
            //console.log(`received message: ${message} from topic: ${topic}`)
          })
        }
      } catch (error) {
        console.log('mqtt.connect error:', error)
      }
    },

    async doSubscribe(topic: string, client: AsyncMqttClient) {
      if (client.connected) {
        try {
          await client.subscribe(topic)
          console.log(`subscribe successfully: ${topic}`)
          return true
        } catch (error) {
          console.log(`subscribe error:, ${error}`)
          return false
        }
      } else {
        console.log('subscribe error: client is not connected')
        return false
      }
    },

    async doUnSubscribe(topic: string, client: AsyncMqttClient) {
      if (client.connected) {
        try {
          await client.subscribe(topic)
          console.log(`unsubscribe successfully: ${topic}`)
          return true
        } catch (error) {
          console.log(`unsubscribe error:, ${error}`)
          return false
        }
      } else {
        console.log('unsubscribe error: client is not connected')
        return false
      }
    },

    async doPublish(
      message: MqttMessage,
      definition: MqttValue,
      client: AsyncMqttClient,
    ) {
      if (client.connected) {
        try {
          let preSubmitOutput

          if (definition.preSubmitFunction) {
            preSubmitOutput = definition.preSubmitFunction(
              message,
              definition.conversionValue,
            )
          } else {
            const finalValue = mqttValueConversion(
              message.payload,
              definition.conversionValue,
              'submit',
            )
            const setValue = JSON.stringify({
              value: finalValue,
            })
            preSubmitOutput = {
              topic: `${message.topic}/set`,
              payload: setValue,
            }
          }

          await client.publish(preSubmitOutput.topic, preSubmitOutput.payload)

          console.log(
            `published message: ${preSubmitOutput.payload} to topic: ${preSubmitOutput.topic}`,
          )
        } catch (error) {
          console.log(`publish error:, ${error}`)
        }
      } else {
        console.log('publish error: client is not connected')
      }
    },

    async doRawPublish(
      topic: string,
      payload: string,
      client: AsyncMqttClient,
    ) {
      if (client.connected) {
        try {
          await client.publish(topic, payload)

          console.log(`published raw message: ${payload} to topic: ${topic}`)
        } catch (error) {
          console.log(`publish error:, ${error}`)
        }
      } else {
        console.log('publish error: client is not connected')
      }
    },

    async destroyConnection(client: AsyncMqttClient) {
      if (client.connected) {
        try {
          await client.end(false)
          this.initData()
          console.log('disconnected successfully')
        } catch (error) {
          console.log('disconnect error:', error)
        }
      }
    },

    initData() {
      this.retryTimes = 0
      this.connected = false
      this.connecting = false
    },

    async handleOnReConnect() {
      this.retryTimes += 1
      console.log('Retrying connection')
      if (this.retryTimes >= 5) {
        try {
          await this.client.end()
          this.initData()
          console.log('connection maxReconnectTimes limit, stop retry')
          this.connectionError = 'error'
        } catch (error) {
          console.log('handleOnReConnect catch error:', error)
          this.connectionError = 'error'
        }
      }
    },

    handleProtocolChange(value: string) {
      this.connection.port = value === 'wss' ? 8084 : 8083
    },

    getSubscriptionsFromDefinition(
      obj: MqttContainer | MqttValue,
    ): MqttSubscription[] {
      const result: MqttSubscription[] = []

      if (instanceOfMqttValue(obj)) {
        const subscription: MqttSubscription = {
          title: obj.title,
          subscribed: false,
          topic: obj.topic,
        }

        result.push(subscription)
      } else if (obj.values && obj.values.length > 0) {
        obj.values.forEach((value) => {
          const valueResult = this.getSubscriptionsFromDefinition(value)
          result.push(...valueResult)
        })
      }

      return result
    },

    getTopicsFromDefinition(obj: MqttContainer | MqttValue): string[] {
      const result: string[] = []

      if (instanceOfMqttValue(obj)) {
        result.push(obj.topic)
      } else if (obj.values && obj.values.length > 0) {
        obj.values.forEach((value) => {
          const valueResult = this.getTopicsFromDefinition(value)
          result.push(...valueResult)
        })
      }

      return result
    },

    updateArray(arr: MqttMessage[], obj: MqttMessage) {
      const foundIndex = arr.findIndex((message) => message.topic === obj.topic)
      foundIndex !== -1 ? (arr[foundIndex] = obj) : arr.push(obj)
    },

    async discoverIPAddress(macAddress: string) {
      const data = await discoverService.fetchIPAddressByMacAddress({
        macAddress: macAddress,
      })

      if (data) this.connection.host = data.InternalIp

      return data
    },
  },
})
